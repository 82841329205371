import React from 'react';
import PropTypes from 'prop-types';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Template from '@clearscore/shared.website-template';
import { Footer, NavigationBar } from '@clearscore/shared.website-drivescore-sections';

import lang from './lib/lang';
import Hero from './components/hero';
import HowItWorks from './components/how-it-works';
import AppDetails from './components/app-details';
import GreatAboutDrivescore from './components/great-about-drivescore';
import AppPreview from './components/app-preview';
import styles from './drivescore-landing.module.css';

// this component is dependent on a script which lives in GTM called DriveScore TrustPilot Widget
const TrustpilotTestimonials = ({ ariaLabel }) => (
    <div aria-label={ariaLabel} className={styles.trustpilotContainer}>
        <div className={styles.desktopWidget}>
            <div
                data-id="trustpilot-widget-testimonials-desktop"
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="53aa8912dec7e10d38f59f36"
                data-businessunit-id="61b60e319b1c042cd38cfa7e"
                data-style-height="140px"
                data-style-width="100%"
                data-theme="dark"
                data-stars="4,5"
                data-review-languages="en"
            />
        </div>
        <div className={styles.mobileWidget}>
            <div
                data-id="trustpilot-widget-testimonials-mobile"
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="61b60e319b1c042cd38cfa7e"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="dark"
                data-stars="4,5"
                data-review-languages="en"
            />
        </div>
    </div>
);

TrustpilotTestimonials.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
};

const DriveScoreLandingPage = ({
    appStoreConfig,
    appStoreLink,
    cookiePolicyConfig,
    images,
    playStoreLink,
    privacyUrl,
}) => {
    const language = useLanguage(lang);
    const urlParams = new URLSearchParams(window.location.search);
    const isCsDonutDeeplink = urlParams.get('utm_source') === 'ClearScore' ?? false;
    const heroLanguage = isCsDonutDeeplink ? { ...language.hero, ...language.heroPromo } : language.hero;

    return (
        <Template
            shouldHideNav
            shouldHideFooter
            appStoreConfig={appStoreConfig}
            cookiePolicyConfig={cookiePolicyConfig}
            pullUpContent
        >
            <div className={styles.drivescoreLandingBackground}>
                <div className={styles.heroGradient} />
                <main className={styles.contentWrapper}>
                    <NavigationBar />
                    <Hero
                        appStoreUrl={appStoreLink}
                        isCsDonutDeeplink={isCsDonutDeeplink}
                        images={{
                            appPreviewHero: images.appPreviewHero,
                            background: images.appPreviewHeroLensFlare,
                        }}
                        playStoreUrl={playStoreLink}
                        {...heroLanguage}
                    />
                    <HowItWorks images={[images.map, images.score, images.save]} {...language.howItWorks} />
                    <AppDetails isReverse image={images.appDetailsAnalyse} {...language.appDetailsAnalyse} />
                    <AppDetails image={images.appDetailsBetterDriver} {...language.appDetailsBetterDriver} />
                    <GreatAboutDrivescore {...language.greatAboutDrivescore} />
                    <TrustpilotTestimonials ariaLabel={language.trustPilotAriaLabel} />
                    <AppPreview
                        image={images.appPreview}
                        isCsDonutDeeplink={isCsDonutDeeplink}
                        playStoreUrl={playStoreLink}
                        appStoreUrl={appStoreLink}
                        {...language.appPreview}
                    />
                    <Footer privacyUrl={privacyUrl} />
                </main>
            </div>
        </Template>
    );
};

DriveScoreLandingPage.propTypes = {
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    appStoreLink: PropTypes.string.isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    images: PropTypes.shape({
        appPreviewHero: PropTypes.object.isRequired,
        appPreviewHeroLensFlare: PropTypes.object.isRequired,
        map: PropTypes.object.isRequired,
        score: PropTypes.object.isRequired,
        save: PropTypes.object.isRequired,
        appDetailsAnalyse: PropTypes.object.isRequired,
        appDetailsBetterDriver: PropTypes.object.isRequired,
        appPreview: PropTypes.object.isRequired,
    }).isRequired,
    playStoreLink: PropTypes.string.isRequired,
    privacyUrl: PropTypes.string.isRequired,
    termsUrl: PropTypes.string.isRequired,
};

export default DriveScoreLandingPage;
