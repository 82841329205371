import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        hero: {
            header: 'Good drivers can save with DriveScore',
            subheader:
                'DriveScore is a new, free app just launched by ClearScore to help you save money on your car insurance.',
            imageAlt: 'App preview on phone',
            trustPilotAriaLabel: 'Trustpilot reviews',
        },
        heroPromo: {
            header: 'DriveScore, the new, <0>free</0> app from ClearScore',
            subheader:
                'Check out your driving insights and see if could save money on your car insurance by downloading DriveScore and recording your drives.',
            imageAlt: 'App preview on phone',
        },
        insightsHero: {
            header: 'Insights',
            subheader:
                'Make your miles go further by using DriveScore to shop for better car insurance, helping you save money. A smarter, fairer way to buy based on how you drive, not how insurers think you drive.',
            imageAlt: 'Car driving',
        },
        appPreview: {
            header: 'The new way to buy car insurance',
            imageAlt: 'App preview on phone',
        },
        howItWorks: {
            sections: [
                {
                    header: 'Drive',
                    text: 'Automatically measure how you drive, turning your phone into your silent coach. DriveScore is easy and quick. It runs in the background, distraction-free, allowing you to focus on the road.',
                    imageAlt: 'Journey on a map',
                },
                {
                    header: 'Score',
                    text: 'Get your score, track changes and see how you compare. DriveScore will show you what’s positive and what to work on. Prove and improve your driving.',
                    imageAlt: 'Score dashboard',
                },
                {
                    header: 'Save',
                    text: 'Use your DriveScore to shop for cheaper car insurance, helping you save money. A smarter, fairer way to buy based on how you drive.',
                    imageAlt: 'Neon pound signs',
                },
            ],
        },
        appDetailsAnalyse: {
            header: 'Analyse your driving behavior',
            text: [
                "DriveScore uses your phone's sensors to track how you drive, identifying where you could improve.",
                'View the full details of your trip, including overall drive rating, insights and trip stats. Dive further into insights by going to the maps page, where you can zoom into the precise location of each driving event recorded.',
            ],
            imageAlt: 'App preview on phone showing details of a recorded trip',
        },
        appDetailsBetterDriver: {
            header: 'Become a better driver',
            text: [
                'DriveScore helps you to build better driving habits by showing you personalised driving tips and highlighting areas that you can improve.',
            ],
            imageAlt: 'App preview on phone showing tips to improve your driving',
        },
        greatAboutDrivescore: {
            header: 'What’s great about DriveScore?',
            listOfFeatures: [
                {
                    icon: 'ShieldWithStar',
                    iconTitle: 'Shield with star icon',
                    title: 'Enhanced insurance offers',
                    description:
                        'Request car insurance quote and choose whether you want to share your score with our insurance partners.',
                },
                {
                    icon: 'CurrencyPound',
                    iconTitle: 'Pound symbol icon',
                    title: 'Saving on car insurance',
                    description:
                        'Offers provided by our insurance partners are informed by your DriveScore, meaning you get prices that are based on your driving behaviour.',
                },
                {
                    icon: 'Padlock',
                    iconTitle: 'Padlock icon',
                    title: 'Your data, your choice',
                    description:
                        "With DriveScore, you're always in control of your data. You choose if you want to share your score.",
                },
                {
                    icon: 'Heart',
                    iconTitle: 'Heart icon',
                    title: 'It’s fair',
                    description:
                        'DriveScore looks at your driving over time, so your insurance is based on how you drive, not how insurers think you drive.',
                },
                {
                    icon: 'SteeringWheel',
                    iconTitle: 'Steering wheel icon',
                    title: 'Smart trip classification',
                    description:
                        'DriveScore learns how you drive and automatically classifies your journeys as drives or not. You can always update your journey classifications manually from your trip list.',
                },
                {
                    icon: 'Target',
                    iconTitle: 'Automatic drive recording',
                    title: 'Automatic drive recording',
                    description:
                        'We automatically record your drives in the background. When you stop driving your trip will be added to your trip list.',
                },
            ],
        },
        trustPilotAriaLabel: 'Trustpilot testimonials',
    },
};
