import React from 'react';

import styles from './subheader.module.css';

interface SubheaderProps {
    children: React.ReactNode;
    tag?: React.ElementType;
}

const Subheader = ({ children, tag: Tag = 'h3' }: SubheaderProps) => <Tag className={styles.subHeader}>{children}</Tag>;

export default Subheader;
