import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import SectionHeader from '../section-header';
import StoreLinks from '../store-links';
import styles from './app-preview.module.css';

const AppPreview = ({ appStoreUrl, image, isCsDonutDeeplink, header, playStoreUrl, imageAlt }) => (
    <section className={styles.appPreviewSection} data-id="app-preview-section">
        <div className={styles.text}>
            <div className={styles.header}>
                <SectionHeader tag="h2">{header}</SectionHeader>
            </div>
            <div className={styles.storeButtons}>
                <StoreLinks
                    playStoreId="app-store-app-preview"
                    isCsDonutDeeplink={isCsDonutDeeplink}
                    appStoreUrl={appStoreUrl}
                    playStoreUrl={playStoreUrl}
                />
            </div>
        </div>
        <div className={styles.image}>
            <GatsbyImage image={image} alt={imageAlt} />
        </div>
    </section>
);

AppPreview.propTypes = {
    appStoreUrl: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    isCsDonutDeeplink: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    playStoreUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
};

export default AppPreview;
