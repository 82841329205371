import useWesbiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';

const STORE_LINK_CLICKED = 'fe_ds_website_store_link_clicked';

export default () => {
    const track = useWesbiteTracking();

    return {
        handleStoreClickTracking: ({ store, utm }) =>
            track({
                name: STORE_LINK_CLICKED,
                props: {
                    e: STORE_LINK_CLICKED,
                    store,
                    utm,
                },
            }),
    };
};
