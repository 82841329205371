import React from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';

import styles from './app-details.module.css';
import SectionHeader from '../section-header';
import Subheader from '../subheader';

interface AppDetailsProps {
    image: IGatsbyImageData;
    id?: string;
    header: string;
    text: string[];
    imageAlt: string;
    isReverse?: boolean;
    tsCsText?: string;
}

const AppDetails = ({ image, id = '', header, text, imageAlt, isReverse = false, tsCsText = '' }: AppDetailsProps) => (
    <section id={id} className={cx(styles.appDetailsSection, { [styles.reverse]: isReverse })} data-id="app-details">
        <div className={styles.sectionText}>
            <SectionHeader>{header}</SectionHeader>
            {text.map((line) => (
                <div key={line} className={styles.sectionTextBody}>
                    <Subheader>{line}</Subheader>
                </div>
            ))}
            {tsCsText ? <div className={styles.tsCsText}>{tsCsText}</div> : null}
        </div>
        <div className={styles.imageContainer}>
            <div className={styles.gradientBackground} />
            <GatsbyImage className={styles.image} image={image} alt={imageAlt} />
        </div>
    </section>
);

export default AppDetails;
