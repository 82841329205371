import React from 'react';

import styles from './header.module.css';

interface HeaderProps {
    children: React.ReactNode;
    tag?: React.ElementType;
}

const Header = ({ children, tag: Tag = 'h1' }: HeaderProps) => <Tag className={styles.header}>{children}</Tag>;

export default Header;
