import React from 'react';

import styles from './section-header.module.css';

interface SectionHeaderProps {
    children: React.ReactNode;
    tag?: React.ElementType;
}

const SectionHeader = ({ children, tag: Tag = 'h2' }: SectionHeaderProps) => (
    <Tag className={styles.sectionHeader}>{children}</Tag>
);

export default SectionHeader;
