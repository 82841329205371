import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.drivescore.text';
import Stack from '@clearscore/ui.rainbow.stack';
import PadlockIcon from '@clearscore/rainbow.icons.padlock';
import CurrencyPoundIcon from '@clearscore/rainbow.icons.currency-gbp-pound';
import HeartIcon from '@clearscore/rainbow.icons.heart';

import styles from './great-about-drivescore.module.css';
import SectionHeader from '../section-header';
import TargetIcon from '../../assets/icons/target.svg';
import SteeringWheelIcon from '../../assets/icons/steering-wheel.svg';
import ShieldwithStarIcon from '../../assets/icons/shield-with-star.svg';

const icons = {
    Target: TargetIcon,
    ShieldWithStar: ShieldwithStarIcon,
    SteeringWheel: SteeringWheelIcon,
    CurrencyPound: CurrencyPoundIcon,
    Padlock: PadlockIcon,
    Heart: HeartIcon,
};

const GreatAboutDrivescore = ({ header, listOfFeatures }) => (
    <section className={styles.greatAboutDrivescoreSection}>
        <SectionHeader>{header}</SectionHeader>
        <div className={styles.featuresLayout}>
            {listOfFeatures.map(({ icon, title, description, iconTitle }) => {
                const Icon = icons[icon];
                return (
                    <span key={title} className={styles.featureWrapper}>
                        <div className={styles.iconBackground}>
                            <Icon title={iconTitle} role="img" className={styles.icon} />
                        </div>
                        <Stack all={Stack.spacings.TINY}>
                            <Text.H5 tag={Text.Tag.H3}>{title}</Text.H5>
                            <div className={styles.description}>
                                <Text.Body1>{description}</Text.Body1>
                            </div>
                        </Stack>
                    </span>
                );
            })}
        </div>
    </section>
);

GreatAboutDrivescore.propTypes = {
    header: PropTypes.string.isRequired,
    listOfFeatures: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GreatAboutDrivescore;
