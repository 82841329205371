import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import interpolateString from '@clearscore-group/lib.helpers.interpolate-with-components';

import Header from '../header';
import Subheader from '../subheader';
import StoreLinks from '../store-links';
import PromoArrow from '../../assets/promo-arrow.svg';
import styles from './hero.module.css';

// this component is dependent on a script which lives in GTM called DriveScore TrustPilot Widget
const TrustPilotHeroWidget = ({ ariaLabel }) => (
    <div aria-label={ariaLabel} className={styles.trustPilotWidgetContainer}>
        <div className={styles.trustPilotWidget}>
            <div
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="5419b6ffb0d04a076446a9af"
                data-businessunit-id="61b60e319b1c042cd38cfa7e"
                data-theme="dark"
                data-style-width="100%"
            />
        </div>
    </div>
);

TrustPilotHeroWidget.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
};

const CianoText = ({ children }) => <span className={styles.cianoText}>{children}</span>;
CianoText.propTypes = { children: PropTypes.string.isRequired };

const Hero = ({
    appStoreUrl,
    images,
    isCsDonutDeeplink,
    header,
    subheader,
    imageAlt,
    playStoreUrl,
    trustPilotAriaLabel,
}) => {
    const [displayPromo, setDisplayPromo] = useState(false);

    useEffect(() => {
        if (isCsDonutDeeplink) {
            setDisplayPromo(true);
        } else {
            setDisplayPromo(false);
        }
    }, [isCsDonutDeeplink]);

    return (
        <section className={styles.heroSection} data-id="hero-section">
            <div className={styles.headerContent}>
                <Header>{interpolateString(header, [{ Component: CianoText }])}</Header>
                <div className={styles.subheader}>
                    <Subheader tag="h2">{subheader}</Subheader>
                </div>
                <div className={styles.cta}>
                    {displayPromo ? (
                        <PromoArrow aria-hidden className={styles.promoArrow} role="img" title="arrow image" />
                    ) : null}
                    <StoreLinks
                        playStoreId="app-store-hero"
                        isCsDonutDeeplink={isCsDonutDeeplink}
                        appStoreUrl={appStoreUrl}
                        playStoreUrl={playStoreUrl}
                    />
                </div>
                <TrustPilotHeroWidget ariaLabel={trustPilotAriaLabel} />
            </div>
            <div className={styles.image}>
                <div aria-hidden className={styles.heroGlitter}>
                    <GatsbyImage image={images.background} alt="" style={{ position: 'unset' }} />
                </div>
                <GatsbyImage image={images.appPreviewHero} alt={imageAlt} />
            </div>
        </section>
    );
};

Hero.propTypes = {
    appStoreUrl: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
    isCsDonutDeeplink: PropTypes.bool.isRequired,
    subheader: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    playStoreUrl: PropTypes.string.isRequired,
    trustPilotAriaLabel: PropTypes.string.isRequired,
};

export default Hero;
