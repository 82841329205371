import React from 'react';
import PropTypes from 'prop-types';

import PlayStoreIcon from './play-store-icon';
import AppStoreIcon from '../../assets/app-store-icon.svg';
import useWebsiteTracking from '../../hooks/tracking';
import styles from './store-links.module.css';

const StoreLinks = ({ appStoreUrl, isCsDonutDeeplink, playStoreId, playStoreUrl }) => {
    const utm = isCsDonutDeeplink ? 'ClearScore' : null;
    const { handleStoreClickTracking } = useWebsiteTracking();
    return (
        <React.Fragment>
            <a
                className={styles.link}
                target="_blank"
                title="App store"
                rel="noopener noreferrer"
                href={appStoreUrl}
                data-qa="store-cta"
                onClick={() => handleStoreClickTracking({ store: 'app_store', utm })}
            >
                <AppStoreIcon className={styles.storeButton} />
            </a>
            <a
                className={styles.link}
                target="_blank"
                title="Play store"
                rel="noopener noreferrer"
                href={playStoreUrl}
                data-qa="store-cta"
                onClick={() => handleStoreClickTracking({ store: 'play_store', utm })}
            >
                <PlayStoreIcon id={playStoreId} />
            </a>
        </React.Fragment>
    );
};

StoreLinks.propTypes = {
    appStoreUrl: PropTypes.string.isRequired,
    isCsDonutDeeplink: PropTypes.bool.isRequired,
    playStoreId: PropTypes.string.isRequired,
    playStoreUrl: PropTypes.string.isRequired,
};

export default StoreLinks;
