import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import styles from './how-it-works.module.css';
import SectionHeader from '../section-header';
import Subheader from '../subheader';

const HowItWorks = ({ images, sections }) => (
    <section className={styles.howItWorksSection}>
        {sections.map(({ header, text, imageAlt }, idx) => (
            <div className={styles.howItWorksItem} key={header}>
                <div className={styles.sectionText}>
                    <SectionHeader>{header}</SectionHeader>
                    <div className={styles.sectionTextBody}>
                        <Subheader>{text}</Subheader>
                    </div>
                </div>
                <div className={styles.image} {...(header === 'Drive' && { style: { '--scaleValue': 1.4 } })}>
                    <GatsbyImage image={images[idx]} alt={imageAlt} />
                </div>
            </div>
        ))}
    </section>
);

HowItWorks.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            imageAlt: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default HowItWorks;
